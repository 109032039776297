import tw from 'twin.macro'
import instagram from '../images/instagramWhite.svg'
import facebook from '../images/facebookWhite.svg'
import twitter from '../images/twitterWhite.svg'
import linkedin from '../images/linkedinVector.svg'
import medium from '../images/mediumWhite.svg'
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Container = tw.div`px-4 md:px-24 py-8 md:py-14  bg-darkGreen text-textWhite`
const MainSection = tw.div` lg:flex `
const Section = tw.div`flex md:block lg:w-1/2 text-center md:text-left flex-col justify-center xl:flex-row  lg:mr-10`
const Content = tw.p`font-light text-sm md:text-base text-center lg:text-left font-mullish  w-full mx-auto xl:mx-0 md:w-4/6  lg:w-full `
const Heading = tw.h2` font-mullish font-bold  text-xl  md:text-center lg:text-left  md:text-lg lg:font-semibold  leading-3 my-6 `
const EmailSection = tw.div`mt-10 w-full lg:w-4/5  h-full sm:h-1/2 flex mt-0 `


const Input = tw.input` w-3/4 p-2 border-0`
const SubButton = tw.button`w-1/3  p-3 bg-mainGreen text-white font-bold border-0 cursor-pointer`
const Copyright = tw.div`font-bold text-center mt-10 lg:mt-14 flex text-center items-center justify-center `
const SocialMedia = tw.div`text-center lg:text-left lg:pl-0 p-2 lg:w-1/3 pt-10 sm:pt-0 md:pt-10 lg:-mt-14`
const Icon = tw.span`mr-5 text-textWhite`
const Form = tw.form` `

const successToast = () => {
  toast.success('You have now subscribed!')
}
const errorToast = () => {
  toast.error('Opps! There was an error')
}

function clearInput() {
  document.getElementById('myInput').value = ''
}

function sendEmail(e) {
  e.preventDefault()

  emailjs
    .sendForm(
      'service_d3sqabj',
      'template_132s5i7',
      e.target,
      'user_3upRs0j9qJy7MUL32vi62'
    )
    .then(
      (e) => {
        console.log(` This what is being sent ${e.text}`)
        successToast()
        clearInput()
      },
      (error) => {
        console.log('Failure!')
        console.log(error.text)
        errorToast()
      },
      e.target.reset
    )
}

const AboutUs = () => {
  return (
    <div>
      <Container>
        <MainSection>
          <Section>
            <div>
              <Heading>Digiscript</Heading>
              <Content>
                Generate leads and accelerate sales with digiscript. Take
                advantage of our Design and Development expertise to take your
                company from good to great!
              </Content>
            </div>
          </Section>

          <Section>
            <div>
              <Heading>Don't miss anything</Heading>
              <Content>
                Join our newsletter to gain valuable infromation that can
                drastically scale your business. You can unsubscribe at any
                time.
              </Content>
              <Form onSubmit={sendEmail}>
                <EmailSection className='Email'>
                  <Input
                    id='myInput'
                    name='to_Name'
                    placeholder='Email'
                  ></Input>

                  <SubButton type='submit' value='Send'>
                    SUBSCRIBE
                  </SubButton>
                </EmailSection>
              </Form>
            </div>
          </Section>
        </MainSection>
        <SocialMedia>
          <Icon>
            <a href='https://twitter.com/thedigiscript' target='blank'>
              <img src={twitter} alt='twitter'></img>
            </a>
          </Icon>
          <Icon>
            <a href='https://www.instagram.com/digiscript/' target='blank'>
              <img src={instagram} alt='instagram'></img>
            </a>
          </Icon>
          <Icon>
            <a
              href='https://www.linkedin.com/company/30084928/admin/'
              target='blank'
            >
              <img src={linkedin} alt='linkedin'></img>
            </a>
          </Icon>
          <Icon>
            <a href='https://www.facebook.com/thedigiscript' target='blank'>
              <img src={facebook} alt='facebook'></img>
            </a>
          </Icon>
          <Icon>
            <a href='https://blog.thedigiscript.com/' target='blank'>
              <img src={medium} alt='medium'></img>
            </a>
          </Icon>
        </SocialMedia>

        <Copyright>© Copyright Digiscript 2022</Copyright>
        <ToastContainer />
      </Container>
    </div>
  )
}

export default AboutUs
