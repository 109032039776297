import React from 'react'
import tw from 'twin.macro';
import Service from '../components/Service'
import dataIcon from '../images/dataIcon.png';
import mobileIcon from '../images/mobileIcon.png';
import webIcon from '../images/webIcon.png';
import seoIcon from '../images/seoIcon.png';

const Services = () => {

    const servicesRendered = [
        {
            icon: mobileIcon,
            heading: 'Mobile App Development',
            body: 'We build highly adaptable, cross-platform mobile applications for modern organisations. Our designs prioritise seamless user interaction and experience while our data-structures are built for efficiency and scale.'
        },
        {
            icon: seoIcon,
            heading: 'Optimization and Security',
            body: 'We pride ourselves in upgrading and maintaining flawless digital experiences for our clients. We use the latest SEO techniques to enable clear communcation between your product and your consumers, as well as protect their personal information with the latest data security principles.'
        },
        {
            icon: webIcon,
            heading: 'Web development',
            body: 'We develop scalable, online experiences that facilitate invaluable results. From a polished, pristine user interface, to a robust API and database'
        },
        {
            icon: dataIcon,
            heading: 'Data Mining And Analysis',
            body: 'Collecting and analysing large streams of data is imperative to business success. Our automation software gurantees profits by generating leads and cutting costs.'
        }
    ]

    const ServicesBackground = tw.div`w-full bg-darkGreen text-white`
    const Container = tw.div`pt-8 pb-16 w-5/6 md:w-4/6 lg:w-5/6 m-auto text-center lg:text-left`
    const AllServices = tw.div`grid grid-cols-1 lg:grid-cols-2 gap-12`
    const HrRule = tw.hr`w-4/6 border-gray-500 lg:float-right lg:w-5/6 md:w-full`
    const Title = tw.h3`font-light tracking-widst uppercase py-2 lg:mt-10 lg:text-right`
    const HeadContainer = tw.div`lg:flex justify-end`
    const ServiceHeading = tw.p`font-bold text-2xl mb-12 md:text-3xl lg:text-4xl lg:w-7/12 lg:text-right mt-0`


    return (
        <ServicesBackground id='services'>
            <Container>
                <HrRule />
                <Title>Our Services</Title>
                <HeadContainer>
                    <ServiceHeading>We've got everything you need to launch and grow your business.</ServiceHeading>
                </HeadContainer>
                <AllServices>
                    {servicesRendered.map((allService, index) => {
                        return (
                            <Service
                                key={index}
                                icon={allService.icon}
                                heading={allService.heading}
                                body={allService.body}
                            />
                        )
                    })}
                </AllServices>
            </Container>
        </ServicesBackground>
    )
}

export default Services
