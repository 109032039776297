import tw from 'twin.macro'
import styled from 'styled-components'
import pic from '../images/Group.png'

const Container = tw.div`max-w-full flex flex-col items-center lg:items-start px-6 sm:px-24 py-12 sm:py-14  lg:pt-14 lg:pb-24 lg:px-16 font-mullish  bg-mainGreen`
const HLine = styled.div`
	${tw`w-9/12 sm:w-full lg:w-10/12 mb-6 sm:mb-10 lg:mb-5`}
	hr {
		${tw`m-0`}
	}
`
const Section = styled.div`
  ${tw`flex flex-col items-center lg:flex-row`}
  div {
    ${tw`lg:w-6/12 xl:w-2/5 flex flex-col items-center lg:items-start mb-10 sm:mb-20 lg:mt-4 xl:-mt-20 2xl:-mt-32 lg:mb-0`}
  }
  img {
    ${tw`w-full lg:w-5/12 xl:w-6/12 mt-0 ml-auto `}
  }
`
const Heading = tw.h2`text-base sm:text-lg lg:text-xl m-0 font-light leading-6 tracking-widst`
const SubHeading = tw.h2`text-3xl sm:text-4xl xl:text-5xl font-bold mt-4 mb-6`
const Content = tw.p`text-base sm:text-lg  font-semibold m-0 text-center lg:text-left leading-7 tracking-wide sm:px-12 lg:px-0`

const AboutUs = () => {
  return (
    <div>
      <Container id='about'>
        <HLine>
          <hr className='hline' />
        </HLine>
        <Section>
          <div>
            <Heading>WHO WE ARE</Heading>
            <SubHeading>We are Digiscript</SubHeading>
            <Content>
              We are a team of software designers and developers dedicated to
              creating effective digital solutions. From web to mobile, we do it
              all here at Digiscript. Looking to generate leads with proven
              online business techniques? Look no further! Want to expand your
              operations with a mobile app? We handle that too!
            </Content>
          </div>
          <img src={pic} alt='aboutUs-icon' className='pic' />
        </Section>
      </Container>
    </div>
  )
}

export default AboutUs
