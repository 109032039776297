import React from 'react'
import tw from 'twin.macro';

const BlogContainer = tw.div`md:px-24 lg:px-0 lg:px-4 xl:p-6`
const Image = tw.img`w-full h-auto block m-auto md:px-0`
const BlogTitle = tw.h4`font-bold pt-4 pb-2`
const BlogDetails = tw.p`leading-6 md:px-4 lg:px-0 md:leading-8`
const ReadMore = tw.a`underline font-bold text-black`

const BlogCard = ({ img, title, description, link }) => {
    const blogD = () => {
        if (description.length > 200) {
            description = description.slice(0, 200) + '... '
        }
        return description
    }
    const blogConcatenated = blogD()

    const blogImage = img
    const imgRegex = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/g
    const extractImg = blogImage.match(imgRegex)

    return (
        <BlogContainer className='blog-space'>
            <Image src={extractImg[0]} alt="blog post" />
            <BlogTitle>{title}</BlogTitle>
            <BlogDetails>
                {blogConcatenated}
                <ReadMore target='blank' href={link}>Read more</ReadMore>
            </BlogDetails>
        </BlogContainer>
    )
}

export default BlogCard
