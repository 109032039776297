//import logo from './logo.svg';
import './App.css';
import Hero from './section/Hero';
import AboutUs from './section/AboutUs';
import ContactUs from './section/ContactUs';
import Services from './section/Services';
import TechBackground from './section/TechBackground';
import Technologies from './section/Technologies';
import Blog from './section/Blog';
import Footer from './section/Footer.js'
import RecentWorks from './section/RecentWorks'

function App() {
  return (
    <div>
      <Hero />
      <AboutUs />
      <Services />
      <TechBackground />
      <RecentWorks/>
      <Technologies/>
      <ContactUs/>
      {/* <Blog /> */}
      <Footer />
    </div>
  )
}

export default App
