import pic1 from '../images/Group 34.png'
// import pic2 from '../images/Group 35.png'
import pic3 from '../images/Group 36.png'
// import Button from '../components/Button'

import Slider from 'react-slick'
import styled from 'styled-components'
import tw from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import { ReactComponent as ArrowLeftIcon } from '../images/arrow-left-2-icon.svg'
import { ReactComponent as ArrowRightIcon } from '../images/arrow-right-2-icon.svg'

import 'slick-carousel/slick/slick.css'

const HLine = styled.div`
	${tw`w-9/12 mx-auto lg:(w-4/6 mx-0 ml-24) xl:w-9/12`}
	hr {
		${tw`m-0`}
	}
`

const Heading = tw.h2`text-base text-center mt-6 font-light leading-6 tracking-widst sm:text-lg  md:mt-10 lg:(text-xl text-left ml-24)   `
const SubHeading = tw.h2`text-3xl  mt-4 mb-6 sm:(text-4xl w-10/12 mx-auto) lg:(text-left w-3/6 ml-24 font-bold) xl:text-5xl text-center  `
const Container = tw.div`relative bg-mainGreen`
const Content = tw.div` md:(px-0 ) `
const TestimonialSliderContainer = tw.div` w-full `
const TestimonialSlider = tw(Slider)`w-full `
const Testimonial = styled.div(({ altIndex }) => [
	tw`w-full flex! flex-col items-center pt-12 pb-4 bg-mainGreen outline-none md:(pt-10 justify-center) lg:(py-12) `,
	altIndex && tw`bg-darkGreen`,
])
const HeaderContainer = styled.div(({ altIndex }) => [
	tw`w-7/8 mb-4 mx-auto md:(w-full mx-0)`,
	altIndex && tw`text-green-50`,
])
const SliderContainer = tw.div`w-7/8 flex flex-col items-center sm:w-full lg:(w-5/6 mx-auto flex-row justify-between) xl:w-7/8`
const ImageContainer = styled.div(({ altIndex }) => [
	tw`w-full flex flex-col items-center sm:w-9/12 md:mx-3 lg:(w-7/12 mx-0) xl:(w-6/12 -ml-3)`,
	altIndex && tw`lg:order-last`,
])
const Image = tw.img`w-full mt-0`
const TextContainer = styled.div(({ altIndex }) => [
	tw`flex flex-col items-center justify-center h-80 sm:w-8/12 md:mx-3 lg:(w-5/12 items-end mx-0) xl:(ml-12 w-4/12)  `,
	altIndex && tw`lg:items-start xl:ml-0`,
])
const ContentHeader = styled.div(({ altIndex }) => [
	tw`tracking-wider font-bold text-3xl my-4 md:text-4xl lg:mt-0 xl:text-5xl  `,
	altIndex && tw`text-white`,
])
const QuoteContainer = tw.div``
const QuoteContent = styled.div(({ altIndex }) => [
	tw`text-base text-center font-roboto leading-7 font-normal m-0 mx-auto md:text-lg lg:(text-right mx-0 ml-4) `,
	altIndex && tw` text-navColor lg:(text-left ml-0)`,
])
const CustomerInfo = tw.div`mt-8`
const LinkButton = styled.button(({ altIndex }) => [
	tw`text-sm font-bold font-mullish py-3 px-6 bg-mainGreen cursor-pointer border-2 border-gray-900 rounded focus:outline-none hover:bg-black hover:text-white transition duration-700 ease-in-out lg:(py-4 px-8) xl:text-base`,
	altIndex &&
		tw`bg-darkGreen border-mainGreen text-mainGreen hover:bg-mainGreen hover:text-darkGreen `,
])
const SliderControlButtonContainer = styled.div`
	${tw`absolute top-0 z-20 md:items-center `}
	button {
		${tw` p-2 w-10 h-10 rounded-full border-none focus:outline-none transition duration-300 transform hover:scale-125 -translate-y-2/3 md:translate-y-0`}
	}
	svg {
		${tw`hidden`}
	}
`

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
	<SliderControlButtonContainer tw='right-5 md:right-14 top-1/2'>
		<button {...props}>
			<ArrowRightIcon />
		</button>
	</SliderControlButtonContainer>
)
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
	<SliderControlButtonContainer tw='left-5 md:left-14 top-1/2'>
		<button {...props}>
			<ArrowLeftIcon />
		</button>
	</SliderControlButtonContainer>
)

const RecentWorks = () => {
	const testimonials = [
		{
			imageSrc: pic1,
			quote:
				'Flenager is an essential management software that equips transporters and petroleum haulage organisations with opportunities for profit and growth by providing them with real-time fleet data.',
			header: 'Flenager',
			link: 'https://www.flenager.com/landing',
		},
		// {
		// 	imageSrc: pic2,
		// 	quote:
		// 		'Taking transportaion in the most populous city in Africa to a new level. Providing acurate, reliable traffic information 24/7.',
		// 	header: 'BRT Lagos',
		// 	link: 'https://www.brtlagos.com/',
		// },
		{
			imageSrc: pic3,
			// quote:
			// 	'Flenager is an essential management software that equips transporters and petroleum haulage organisations with opportunities for profit and growth by providing them with real-time fleet data.',
			quote:
				'Bring Your Real Estate Dream To Life. We will help you locate, Develop, Construct or Renovate a place you will love',
			header: 'Aslan Properties',
			link: 'https://aslanproperties.com.ng/',
		},
	]
	return (
		<Container>
			<Content>
				<TestimonialSliderContainer>
					<TestimonialSlider
						nextArrow={<NextArrow />}
						prevArrow={<PreviousArrow />}
					>
						{testimonials.map((testimonial, index) => {
							if (index % 2 === 0) {
								return (
									<Testimonial key={index}>
										<HeaderContainer>
											<HLine>
												<hr className='hline' />
											</HLine>
											<Heading>RECENT WORKS</Heading>
											<SubHeading>
												We love what we do, check out some of our latest works
											</SubHeading>
										</HeaderContainer>
										<SliderContainer>
											<ImageContainer>
												<Image
													src={testimonial.imageSrc}
													alt={testimonial.header}
												/>
											</ImageContainer>
											<TextContainer>
												<ContentHeader>{testimonial.header}</ContentHeader>
												<QuoteContainer>
													<QuoteContent>{testimonial.quote}</QuoteContent>
												</QuoteContainer>
												<CustomerInfo>
													<a
														href={testimonial.link}
														rel='noreferrer'
														target='_blank'
													>
														<LinkButton>VIEW WEBSITE</LinkButton>
													</a>
												</CustomerInfo>
											</TextContainer>
										</SliderContainer>
									</Testimonial>
								)
							} else {
								return (
									<Testimonial key={index} altIndex>
										<HeaderContainer altIndex>
											<HLine>
												<hr className='hline' />
											</HLine>
											<Heading>RECENT WORKS</Heading>
											<SubHeading>
												We love what we do, check out some of our latest works
											</SubHeading>
										</HeaderContainer>
										<SliderContainer>
											<ImageContainer altIndex>
												<Image
													src={testimonial.imageSrc}
													alt={testimonial.header}
												/>
											</ImageContainer>
											<TextContainer altIndex>
												<ContentHeader altIndex>
													{testimonial.header}
												</ContentHeader>
												<QuoteContainer>
													<QuoteContent altIndex>
														{testimonial.quote}
													</QuoteContent>
												</QuoteContainer>
												<CustomerInfo>
													<a
														href={testimonial.link}
														rel='noreferrer'
														target='_blank'
													>
														<LinkButton altIndex>VIEW WEBSITE</LinkButton>
													</a>
												</CustomerInfo>
											</TextContainer>
										</SliderContainer>
									</Testimonial>
								)
							}
						})}
					</TestimonialSlider>
				</TestimonialSliderContainer>
			</Content>
		</Container>
	)
}

export default RecentWorks
