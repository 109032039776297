import React from 'react'
import tw from 'twin.macro'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import bento from '../images/bento.png'
import ngx from '../images/ngx_group_logo.png'
import swansea from '../images/swansea.png'
import universityOfYork from '../images/university-of-york.png'

const ImgContainer = tw.div`my-8`
const Text = tw.p`tracking-widst uppercase lg:text-2xl text-center leading-snug font-light`
const SliderContainer = tw.div`w-5/6 mx-auto my-12`
const Image = tw.img`m-auto w-2/4 h-auto`

const TechBackground = () => {
  const tech = [bento, ngx, swansea, universityOfYork]

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <SliderContainer>
      <Text>A Technical team with technical backgrounds</Text>
      <Slider {...settings}>
        {tech.map((technical) => (
          <ImgContainer>
            <Image src={technical} alt='technical background skills' />
          </ImgContainer>
        ))}
      </Slider>
    </SliderContainer>
  )
}

export default TechBackground
