/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as LocationIcon } from '../images/location.svg'
import { ReactComponent as MailIcon } from '../images/mail-outlin.svg'
import { ReactComponent as CallIcon } from '../images/call.svg'
import emailjs from 'emailjs-com';


import { ReactComponent as LinkIcon } from '../images/Link.svg'
import { ReactComponent as IGIcon } from '../images/ig.svg'
import { ReactComponent as TwitterIcon } from '../images/twitterSmall.svg'
import { ReactComponent as FBIcon } from '../images/fb.svg'



const Container = tw.div`relative bg-darkGreen lg:h-3/6`;
const TwoColumn = tw.div`flex flex-col md:flex-col lg:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`lg:w-5/12 flex-shrink-0 md:h-auto `;
const TextColumn = styled(Column)(props => [
  tw`md:w-full md:mt-0`,
  props.textOnLeft ? tw`md:order-first` : tw`lg:ml-16 md:order-last`
]);

const TextContent = tw.div`w-full  lg:py-8 text-center`;
const Form = tw.form`w-10/12 mx-auto md:w-8/12 lg:(w-9/12) text-sm flex flex-col items-center max-w-sm mr-auto md:mx-auto max-w-screen-md	`
const Input = tw.input`w-full mt-6 first:mt-0  placeholder-white border-0 border-b-2 max-w-lg font-mullish	max-w-screen-md	text-white bg-darkGreen h-16 focus:outline-none font-medium border-gray-500 transition duration-300 hocus:border-mainGreen`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`w-full h-36 border-0 bg-darkGreen border-gray-500 placeholder-white font-mullish focus:outline-none	mt-8 text-white border-b-2`}`

const PrimaryButtonBase = tw.button`px-8 py-3 font-bold rounded bg-mainGreen text-gray-100 hocus:bg-white hocus:text-mainGreen focus:shadow-sm focus:outline-none transition duration-300`;
const SubmitButton = tw(
  PrimaryButtonBase
)`px-10 font-mullish flex flex-col items-center mt-10 border-0 font-medium cursor-pointer`

const HLine = styled.div`
	${tw`w-8/12 mx-auto md:w-9/12 xl:w-8/12 lg:mx-0 xl:mr-52 lg:mr-24 lg:float-right lg:ml-24`}

	hr {
		${tw`m-0 border-gray-500`}
	}`

const Heading = tw.h2`text-base text-center sm:text-lg lg:text-xl lg:text-right xl:mr-52 lg:mr-24 mt-6 md:mt-10 lg:ml-24 font-light leading-6 tracking-widst text-gray-400`
const Content = tw.div`px-4 md:px-0 py-12 md:py-14	`
const HeadContainer = tw.div`lg:flex justify-end text-center`
const ContactHeading = tw.p`font-bold text-3xl sm:text-4xl xl:text-5xl mb-12 md:(text-3xl w-7/12 mx-auto) lg:text-4xl lg:w-5/12 lg:text-right mt-0 text-white xl:mr-52 lg:mr-24`



const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 lg:text-left border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10  px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex-initial	lg:flex items-start mt-16 first:mt-0`}
    a {
      ${tw`no-underline`}
    }
    .icon {
      ${tw`w-11/12 lg:w-10 h-10 mb-3 flex justify-center text-gray-500 ml-5 lg:ml-0 lg:mr-5 flex-shrink-0`}
    }
    .social{
      ${tw`w-12 h-12 text-gray-500 lg:mr-6 flex-shrink-0 `}
    }
    .text {
      ${tw`font-semibold text-lg text-white tracking-wide ml-3`}
    }
  }
`;

export default ({
  submitButtonText = "SEND",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [showMessage, isShowing] = useState(false);
  const messageShowing = () => isShowing(true);
  const messageNotShowing = () => isShowing(false);

  function refreshPage() {
    window.location.reload(false)
  }

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_d3sqabj',
        'template_r4x0vgn',
        e.target,
        'user_3upRs0j9qJy7MUL32vi62'
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log('Success!');
          messageShowing();

          refreshPage();
        },
        (error) => {
          console.log('Failure!');
          console.log(error.text);
          messageNotShowing();
        },
        e.target.reset
      );
  }
  return (
    <Container id='contact'>
      <Content>
        <HLine>
          <hr className='hline' />
        </HLine>


        <Heading>CONTACT US</Heading>
        <HeadContainer>
          <ContactHeading>
            Have an idea? Tell us about your product.
          </ContactHeading>
        </HeadContainer>

        <TwoColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>

              <Form onSubmit={sendEmail}>
                <Input type='text' name='from_name' placeholder='Name' required />
                <Input type='email' name='from_email' placeholder='Email' required />
                <Textarea name='message' placeholder='Your Message' required />

                {showMessage ? (
                  <p style={{ marginTop: '10px' }} tw='text-white'>Your request has been sent.</p>
                ) : (
                  <div></div>
                )}
                <SubmitButton type="submit" value='Send'>{submitButtonText}</SubmitButton>
              </Form>

            </TextContent>
          </TextColumn>

          <ImageColumn tw='p-0'>
            <TextContent>
              <PlanFeatures tw='lg:mt--4'>
                <li className="feature" style={{ listStyle: "none" }}>
                  <LocationIcon className="icon" />
                  <span className="text" >
                    7 Ibiyinka Olorunbe Victoria
                    <br />
                    Island Lagos, Nigeria
                  </span>
                </li>
                <li className="feature" style={{ listStyle: "none" }} >
                  <MailIcon className="icon" />
                  <span className="text">
                    info@thedigiscript.com
                  </span>
                </li>
                <li className="feature" style={{ listStyle: "none" }}>
                  <CallIcon className="icon" />
                  <span className="text">
                    (+44) 755799419
                    <br></br>
                    (+234) 9032610594
                  </span>
                </li>

                <li className="feature" style={{ listStyle: "none" }}>
                  <a target='blank' href='https://www.linkedin.com/company/30084928/admin/'><LinkIcon className="social" target='blank' /> </a>
                  <a target='blank' href='https://www.instagram.com/digiscript/'><IGIcon className="social" /> </a>
                  <a target='blank' href='https://twitter.com/thedigiscript'><TwitterIcon className="social" target='blank' /> </a>
                  <a target='blank' href='https://www.facebook.com/thedigiscript'><FBIcon className="social" target='blank' /> </a>


                </li>
              </PlanFeatures>
            </TextContent>
          </ImageColumn>
        </TwoColumn>
      </Content>
    </Container>
  );
};

