import React, { useState, useEffect, useRef } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import image from '../asset/images/digiscriptbg.png'
import Navbar from './Navbar'
import Dropdown from './Dropdown'

import mediumCircle from '../images/medium-circle.svg'
import instagramCircle from '../images/instagram-circle.svg'
import twitterCircle from '../images/twitter-circle.svg'
import linkedinCircle from '../images/linkedin-circle.svg'
import facebookCircle from '../images/facebook-circle.svg'

const Container = styled.div`
  ${tw` relative  bg-center bg-cover min-h-screen `}
  background-image: url(${image});
`

const Heading = tw.h1`text-base md:text-lg lg:text-xl text-textWhite font-light font-mullish capitalize  tracking-widest leading-6 mt-28`
const SubHeading = tw.p`lg:w-10/12 text-textWhite text-2xl sm:leading-8 font-bold lg:text-5xl md:leading-10 md:text-4xl`
const HeroContent = tw.div`w-full lg:w-3/5 md:p-8 text-center lg:text-left  justify-center items-center mt-32 lg:mt-0`
const Button = tw.button` bg-baseBackground uppercase text-base  border-textWhite text-textWhite py-4 px-10  font-bold rounded border-textWhite cursor-pointer hover:bg-textWhite hover:text-baseBackground`
const Content = tw.div` w-full flex`
const Drop = tw.div`absolute right-0 top-0 `
const OpacityOverlay = tw.div`absolute inset-0 opacity-50 bg-black `
const HeroContainer = tw.div` z-20 relative  sm:px-8 mx-auto  inline-block `
const SocialColumn = tw.div`  right-0  ml-auto w-10 h-2/5   justify-center items-center pt-52 hidden lg:block`
const Icon = tw.div` pt-1`

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false)
      }
    }

    let handler = (event) => {
      if (!menuRef.current.contains(event.target) && setIsOpen() === true) {
        setIsOpen(false)
      }
    }

    window.addEventListener('mousedown', handler)

    window.addEventListener('resize', hideMenu)

    return () => {
      window.removeEventListener('resize', hideMenu)
      document.removeEventListener('mousedown', handler)
    }
  }, [isOpen])

  let menuRef = useRef()

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <Navbar toggle={toggle} isOpen={isOpen} />
        <Drop ref={menuRef}>
          <Dropdown isOpen={isOpen} toggle={toggle} />
        </Drop>

        <Content>
          <HeroContent>
            <Heading>WELCOME TO DIGISCRIPT</Heading>
            <SubHeading>
              We create unique digital experiences for businesses of the future.
            </SubHeading>
            <a
              href="/contact"
              onClick={(e) => {
                let contact = document.getElementById("contact");
                e.preventDefault();
                contact && contact.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
            >
              <Button>Get Started</Button>
            </a>
            {/* <a href='#contact'><Button>Get Started</Button></a> */}
          </HeroContent>
          <SocialColumn>
            <Icon>
              <a href='https://blog.thedigiscript.com/' target='blank'>
                <img src={mediumCircle} alt='medium'></img>
              </a>
            </Icon>
            <Icon>
              <a href='https://www.instagram.com/digiscript/' target='blank'>
                <img src={instagramCircle} alt='instagram'></img>
              </a>
            </Icon>
            <Icon>
              <a href='https://twitter.com/thedigiscript' target='blank'>
                <img src={twitterCircle} alt='twitter'></img>
              </a>
            </Icon>
            <Icon>
              <a
                href='https://www.linkedin.com/company/30084928/admin/'
                target='blank'
              >
                <img src={linkedinCircle} alt='linkedin'></img>
              </a>
            </Icon>
            <Icon>
              <a href='https://www.facebook.com/thedigiscript' target='blank'>
                <img src={facebookCircle} alt='facebook'></img>
              </a>
            </Icon>
          </SocialColumn>
        </Content>
      </HeroContainer>
    </Container>
  )
}

export default Hero
