import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import ReactLogo from '../images/react.png'
import VueLogo from '../images/vue.png'
import FlutterLogo from '../images/flutter.png'
import NodeLogo from '../images/node-js.png'
import HerokuLogo from '../images/heroku.png'
import FirebaseLogo from '../images/firebase.png'
import SwiftLogo from '../images/swift.png'
import BitbucketLogo from '../images/bitbucket.png'
import MongoLogo from '../images/mongo-db.png'

const Container = tw.section`w-full py-12 sm:py-14 md:py-20 text-center `
const Wrapper = tw.div`w-11/12 mx-auto`
const Heading = tw.h2`w-11/12 text-center font-light text-base lg:text-2xl mx-auto mt-0 mb-10 md:mb-12 tracking-widst`
const SubHeading = tw.div`flex justify-around sm:block sm:space-x-16 md:space-x-28 text-sm md:text-lg text-blackGrey font-medium mb-20`
const Content = styled.div`
  ${tw`space-y-16`}
  img {
    ${tw`h-16 md:h-20 `}
  }
  div {
    ${tw` sm:space-x-16 md:space-x-24`}
  }
  .content-1 {
    ${tw`flex justify-between sm:block`}
  }
  .content-2 {
    ${tw`flex justify-around sm:block`}
  }
`

const Technologies = () => {
  return (
    <Container>
      <Wrapper>
        <Heading>TECHNOLOGIES WE WORK WITH</Heading>
        <SubHeading>
          <span>Mobile</span>
          <span>Frontend</span>
          <span>Database</span>
          <span>Backend CI/CD</span>
        </SubHeading>
        <Content>
          <div className='content-1'>
            <img src={ReactLogo} alt='React-logo' />
            <img src={VueLogo} alt='Vue-logo' />
            <img src={FlutterLogo} alt='Flutter-logo' />
            <img src={NodeLogo} alt='Node-logo' />
            <img src={HerokuLogo} alt='Heroku-logo' />
          </div>
          <div className='content-2'>
            <img src={FirebaseLogo} alt='Firebase-logo' />
            <img src={SwiftLogo} alt='Swift-logo' />
            <img src={BitbucketLogo} alt='Bitbucket-logo' />
            <img src={MongoLogo} alt='MongoDB-logo' />
          </div>
        </Content>
      </Wrapper>
    </Container>
  )
}

export default Technologies
