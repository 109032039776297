import React from 'react';
import tw from 'twin.macro';

const Service = ({ icon, heading, body }) => {

    const ServiceContainer = tw.div`p-4 text-white border-solid border border-gridColor rounded-lg`
    const OurService = tw.div`lg:flex py-8 lg:py-0 lg:pt-4`
    const ServiceBox = tw.div`flex flex-col`
    const ServiceHeading = tw.h3`mb-0`
    const ServiceBody = tw.p`text-base md:px-16 lg:px-0`
    const Icons = tw.div`lg:pr-4`
    return (
        <ServiceContainer>
            <OurService>
                <Icons>
                    <img src={icon} alt="services icons" />
                </Icons>
                <ServiceBox>
                    <ServiceHeading>{heading}</ServiceHeading>
                    <ServiceBody>{body}</ServiceBody>
                </ServiceBox>
            </OurService>
        </ServiceContainer>
    )
}

export default Service
