import styled from 'styled-components'

import tw from 'twin.macro'
import logo from '../asset/images/digiscript-logo.png'
import menubar from '../asset/icons/HAMBURGER MENU (1).svg'

import close from '../asset/icons/HAMBURGER MENU (close).svg'

const Navbutton = styled.nav`
  ${tw` flex justify-between items-center p-4 `}
  /* .link {
    ${tw`uppercase no-underline  font-semibold text-base text-navColor p-4 hidden md:inline-block lg:inline-block `}
  } */
  .menubar {
    ${tw`  mt-4 block cursor-pointer `}
  }
  .icon {
    ${tw`w-10 h-10 text-navColor `}
  }
`
const Navlinks = styled.div`
  ${tw`w-full pr-12 lg:inline-flex lg:flex-grow lg:w-auto flex flex-row  `}
`

const Logo = tw.a`w-14 h-14 md:w-16 md:h-16 lg:w-20 lg:h-20`

const Navigation = styled.nav`
  ${tw` uppercase hidden m-2 text-base font-semibold  font-mullish text-navColor font-normal `}
`

const Menu = styled.div`flex-wrap w-1/3 `

const Navbar = ({ toggle, isOpen }) => {
  // const toggle = () => {
  //   setShowLinks(!showLinks);
  // };

  return (
    <>
      <Navbutton>
        <Logo>
          <a href='/' className='logo'>
            <img src={logo} width='110' alt='digiscript-logo' />
          </a>
        </Logo>

        <Menu>
          <div className=''>
            <img
              src={menubar}
              alt='menubar'
              onClick={toggle}
              className='menubar'
            />
          </div>
        </Menu>
      </Navbutton>
    </>
  )
}

export default Navbar
