import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import Linkedin from '../images/linkedinWhite.svg'
import Instagram from '../images/instagramWhite.svg'
import Twitter from '../images/twitterWhite.svg'
import Facebook from '../images/facebookWhite.svg'
import Medium from '../images/mediumWhite.svg'

const CopyrightCaption = tw.div` w-full font-mullish text-sm   mt-8`
const DropLink = styled.div`
  ${tw`text-left  items-start justify-start  text-base font-semibold font-mullish  text-navColor font-bold mt-8`}
`
const DropLinks = styled.div`
  ${tw`   w-4/5 md:max-h-screen bg-baseBackground text-textWhite  p-10 `}
`
const Caption = tw.div`w-full font-mullish text-sm   leading-5  mt-10 text-darkGrey `
const SocialMedia = tw.div`text-center lg:text-left  p-2 lg:w-full pt-10 sm:pt-0 md:mt-10  items-center justify-center`
const Icon = tw.span`mr-5`

const Dropdown = ({ isOpen, toggle }) => {
  return (
    <main>
      <AnimatePresence>
        {isOpen && (
          <motion.aside
            initial={{ width: 0 }}
            animate={{ width: 252, height: 1045 }}
            exit={{ width: 0 }}
          >
            <DropLinks onClick={toggle} key={1}>
              <motion
                initial='hidden'
                animate='visible'
                variants={{
                  hidden: { x: 0, opacity: 0 },
                  visible: {
                    x: 10,
                    opacity: 1,
                    transition: { duration: 0.5 },
                  },
                }}
                whileHover={{
                  scale: [1, 1.1, 1],
                  transition: { duration: 0.4 },
                }}
              >
                <Link to='/'>
                  <DropLink>Home</DropLink>
                </Link>
                <Link
                  to="/about"
                  onClick={() => {
                    let about = document.getElementById("about");
                    about && about.scrollIntoView({ behavior: "smooth", block: "start" });
                  }}
                >
                  <DropLink>About</DropLink>
                </Link>
                <Link
                  to="/services"
                  onClick={() => {
                    let services = document.getElementById("services");
                    services && services.scrollIntoView({ behavior: "smooth", block: "start" });
                  }}
                >
                  <DropLink>Services</DropLink>
                </Link>
                <Link
                  to="/blog"
                  onClick={() => {
                    let blog = document.getElementById("blog");
                    blog && blog.scrollIntoView({ behavior: "smooth", block: "start" });
                  }}
                >
                  <DropLink>Blog</DropLink>
                </Link>
                <Link
                  to="/contact"
                  onClick={() => {
                    let contact = document.getElementById("contact");
                    contact && contact.scrollIntoView({ behavior: "smooth", block: "start" });
                  }}
                >
                  <DropLink>Contact</DropLink>
                </Link>
                <SocialMedia>
                  <Icon>
                    <a
                      href='https://www.instagram.com/digiscript/ '
                      target='blank'
                    >
                      <img src={Instagram} alt='instagram'></img>
                    </a>
                  </Icon>
                  <Icon>
                    <a href='https://twitter.com/thedigiscript' target='blank'>
                      <img src={Twitter} alt='twitter'></img>
                    </a>
                  </Icon>
                  <Icon>
                    <a
                      href='https://www.linkedin.com/company/30084928/admin/'
                      target='blank'
                    >
                      <img src={Linkedin} alt='linkedin'></img>
                    </a>
                  </Icon>
                  <Icon>
                    <a
                      href='https://www.facebook.com/thedigiscript'
                      target='blank'
                    >
                      <img src={Facebook} alt='facebooko'></img>
                    </a>
                  </Icon>
                  <Icon>
                    <a href='https://blog.thedigiscript.com' target='blank'>
                      <img src={Medium} alt='facebooko'></img>
                    </a>
                  </Icon>
                </SocialMedia>
                <Caption>
                  Generate leads and accelerate sales with digiscript. Take
                  advantage of our Design and Development expertise to take your
                  company from good to great!
                </Caption>
                <CopyrightCaption>© Copyright Digiscript 2022</CopyrightCaption>
              </motion>
            </DropLinks>
          </motion.aside>
        )}
      </AnimatePresence>
    </main>
  )
}

export default Dropdown
